<template>
  <router-link
    :to="{ name: 'tourism_open', params: { link: link, id: item.id, url: item.link } }"
    class="a-item"
  >
    <div class="a-item__image">
      <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
    </div>
    <div class="a-item__body">
      <span class="a-item__category" v-if="item.category">{{ item.category.title }}</span>
      <h2 class="a-item__title">{{ item.title }}</h2>
      <span v-if="item.description" class="a-item__subtitle">
        <EditorJSComponent :text="JSON.parse(item.description)" />
      </span>
    </div>
  </router-link>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";
export default {
  name: "AccommodationItemComponent",
  components: { EditorJSComponent },
  props: {
    link: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
@import "~@/styles/mixins/textcut.styl"
.a-item {
  display grid
  grid-gap 16px
  padding 16px
  box-sizing border-box
  border: 1px solid var(--color_gray_divider);

  &__image {
    width 100%

    img {
      height 260px
      width 100%
      object-fit cover
      object-position center
    }
  }

  &__body {
    display grid
    grid-gap 8px
  }

  &__category {
    font-weight: normal;
    font-size: 0.75;
    line-height: 18px;
    color: var(--color_blue);
  }

  &__title {
    font-weight: 500;
    font-size: 1.375em;
    line-height: 28px;
    color: var(--color_dark);
    margin 0
  }

  &__subtitle {
    //font-weight: normal;
    //font-size: 0.875em;
    //line-height: 20px;
    color: var(--color_dark);
    textcut(2)
  }
}
</style>
