<template>
  <section class="t-accommodation">
    <div class="t-accommodation__content">
      <TourismSearchComponent ref="search" :categories="categories" @change="handleSearchChange" />
      <AccommodationItemsList ref="pagination" @change="handlePageChange" />
    </div>
  </section>
</template>

<script>
import TourismSearchComponent from "../../components/TourismSearchComponent.vue";
import AccommodationItemsList from "./components/AccommodationItemsList.vue";

export default {
  name: "TourismAccommodationPage",
  data() {
    return {
      loading: false,
      category: null,
      searchQuery: null,
      page: 1,
      first: 16,
    };
  },
  computed: {
    categories() {
      let categories = [];
      if (this.$store.state.tourism.category_accommodation) {
        categories = categories.concat(this.$store.state.tourism.category_accommodation);
      }
      return categories;
    },
  },
  methods: {
    handleSearchChange(form) {
      this.category = form.category_id.value;
      this.searchQuery = form.title.value;
      this.loading = true;
      this.reload();
    },
    handlePageChange(page) {
      this.page = page;
      this.loading = true;
      this.reload();
    },
    stopLoading() {
      this.loading = false;
      this.$refs.search.stopLoading();
      this.$refs.pagination.stopLoading();
    },
    reload() {
      this.$emit("reload", this.$store, this.$route, {
        category_id: this.category?.id,
        title: this.searchQuery,
        page: this.page,
        first: this.first,
      });
    },
  },
  components: { AccommodationItemsList, TourismSearchComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/tourism/accommodation.styl"
</style>
