<template>
  <div class="send-idea">
    <SendIdeaIcon />
    <h2 class="send-idea__title">Развитие туризма</h2>
    <span class="send-idea__subtitle">
      Вы можете учавствовать в развитии туризма в нашем регионе. Присылайте ваши идеи, новые маршруты, места.
    </span>
    <button class="btn-blue" type="button" @click="showSendIdeaModal">Направить идею</button>
  </div>
</template>
<script>
import SendIdeaIcon from "./svg/SendIdeaIcon.vue";
import SendIdeaModal from "./SendIdeaModal.vue";

export default {
  name: "SendIdeaComponent",
  methods: {
    showSendIdeaModal() {
      this.$store.state._modals.push({
        component: SendIdeaModal,
      });
    },
  },
  components: { SendIdeaIcon },
};
</script>

<style lang="stylus">
.send-idea {
  display flex
  flex-direction column
  justify-content center
  align-items center
  padding 0 64px
  height 490px
  background var(--color_gray_divider)
  +below(1200px) {
    position relative
    padding 24px
    padding-left 120px
    height auto
    justify-content flex-start
    align-items flex-start
    +below(560px) {
      padding 16px
    }
  }

  .icon {
    width 134px
    height 134px
    +below(1200px) {
      width 80px
      height 80px
      absolute left 24px top 24px
    }
    +below(560px) {
      width 32px
      height 32px
      absolute left 16px top 16px
    }

    svg {
      width 100%
      height 100%
    }
  }

  > button {
    width 100%
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    +below(1200px) {
      width auto
    }
    +below(560px) {
      width 100%
    }
  }

  &__title {
    margin 24px 0 16px
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    text-align: center;
    color: var(--color_dark);
    +below(1200px) {
      margin 0
      text-align: left;
    }
    +below(560px) {
      padding-left 40px
    }
  }

  &__subtitle {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    text-align: center;
    color: var(--color_dark);
    margin-bottom 24px
    +below(1200px) {
      margin 16px 0 24px
      text-align: left;
    }
    +below(560px) {
      margin 22px 0 16px
    }
  }
}
</style>
