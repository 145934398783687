<template>
  <article class="r-item">
    <div class="r-item__image-container">
      <img
        :src="item.head_img | image($store.state.api)"
        :alt="item.head_img | image_alt"
        class="r-item__image"
      />
    </div>
    <div class="r-item__body" ref="body">
      <h2 class="r-item__title">{{ item.title }}</h2>
      <div v-if="hasText(item.description)" class="r-item__subtitle">
        <EditorJSComponent v-if="isJson(item.description)" :text="JSON.parse(item.description)" />
        <span v-else>{{ item.description }}</span>
      </div>
      <div class="r-item__article" :class="{ 'r-item__article--active': show }">
        <template v-if="hasText(item.text)">
          <EditorJSComponent :text="JSON.parse(item.text)" v-if="isJson(item.text)" />
          <span v-else>{{ item.text }}</span>
        </template>
        <router-link
          :to="{ name: 'tourism_open', params: { link: 'routes', id: item.id, url: item.link } }"
          class="btn-transpar_blue_dark"
        >
          Перейти к описанию маршрута
        </router-link>
      </div>
      <button
        type="button"
        @click="toggleShow"
        class="r-item__show-toggle"
        :class="{ 'r-item__show-toggle--active': show }"
      >
        <ArrowDown />
        <span>
          <template v-if="show">Скрыть подробности</template>
          <template v-else>Подробнее</template>
        </span>
      </button>
    </div>
  </article>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";
import ArrowDown from "components/svg/ArrowDown.vue";

export default {
  name: "RouteItemComponent",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    offset(el) {
      let rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    },
    toggleShow() {
      this.show = !this.show;
      if (!this.show) {
        window.scrollTo(0, this.offset(this.$refs.body).top);
      }
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    hasText(text) {
      if (this.isJson(text)) {
        let parsedText = JSON.parse(text);
        return !!(parsedText && parsedText.blocks && parsedText.blocks.length);
      }
      return !!text;
    },
  },
  components: { ArrowDown, EditorJSComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.r-item {
  display grid
  grid-template-columns 3fr 9fr
  grid-gap 32px
  align-items start
  +below(1200px) {
    grid-gap 24px
    grid-template-columns 4fr 8fr
  }
  +below(768px) {
    grid-template-columns 1fr
  }
  +below(560px) {
    grid-gap 16px
  }

  &__image {
    width 100%
    height 100%
    object-fit cover
    object-position center
    transition .2s

    &-container {
      overflow hidden
      border-radius 4px
      width 100%
      +below(768px) {
        height 196px
      }

      &:hover img {
        transform scale(1.08)
      }
    }
  }

  &__show-toggle {
    display grid
    grid-template-columns 24px auto
    grid-gap 6px
    background transparent
    border none
    padding 0
    align-items center
    cursor pointer

    &--active .icon {
      transform rotate(180deg)
    }

    .icon {
      width 24px
      height 24px

      svg {
        svg(var(--color_gray_dark))
      }
    }

    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: var(--color_gray_dark);
    }
  }

  &__body {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    grid-gap 16px
  }

  &__title {
    font-weight: 500;
    font-size: 1.375em;
    line-height: 28px;
    color: var(--color_black);
    margin 0
    +below(768px) {
      font-size: 19px;
      line-height: 24px;
    }
  }

  &__subtitle {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_black);
    +below(768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__article {
    display none
    flex-direction column
    align-items flex-start
    justify-content flex-start

    .btn-transpar_blue_dark {
      margin-top 40px
      min-height auto
      font-weight: normal;
      font-size: 1em;
      line-height: 24px;
      height 44px
    }

    &--active {
      display flex
    }
  }
}
</style>
