<template>
  <div v-if="slides && slides.length" class="b-slider">
    <swiper ref="swiper" :options="swiperOptions" @slideChange="change">
      <swiper-slide v-for="(item, index) in slides" :key="index">
        <router-link :to="item.route" class="b-slide">
          <img
            :src="item.head_img | image($store.state.api)"
            :alt="item.head_img | image_alt"
            class="b-slide__img"
          />
          <div class="b-slide__content">
            <h2 class="b-slide__title">{{ item.title }}</h2>
            <div class="b-slide__subtitle">
              <span v-if="item.category">{{ item.category.title }}</span>
              <span>{{ item.date_publication | humanDateSliderRu }}</span>
            </div>
          </div>
        </router-link>
      </swiper-slide>
    </swiper>
    <div v-if="slides.length >= 2" class="b-slider__arrows">
      <button @click.prevent="swiper.slidePrev()" type="button" class="b-slider__arrow b-slider__arrow--left">
        <ArrowLeft />
      </button>
      <div class="b-slider__dots">
        <button
          v-for="(_, i) in slides"
          :key="i"
          :class="{ active: activeSlide === i }"
          @click="swiper.slideTo(i)"
          type="button"
        ></button>
      </div>
      <button
        @click.prevent="swiper.slideNext()"
        type="button"
        class="b-slider__arrow b-slider__arrow--right"
      >
        <ArrowRight />
      </button>
    </div>
  </div>
</template>

<script>
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";
import ArrowLeft from "@/components/svg/ArrowLeft.vue";
import ArrowRight from "@/components/svg/ArrowRight.vue";

export default {
  name: "RouteSlider",
  props: {
    slides: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      activeSlide: 0,
      loading: false,
      swiperOptions: {
        slidesPerView: 1,
        loop: false,
        clickable: true,
        speed: 800,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        preloadImages: false,
        lazy: {
          loadOnTransitionStart: true,
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper;
    },
  },
  methods: {
    change() {
      this.activeSlide = this.swiper.activeIndex;
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    ArrowLeft,
    ArrowRight,
  },
  directives: {
    swiper: directive,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/swiper.styl"
.b-slider {
  position relative
  width 100%

  .swiper-container {
    width 100%
    border-radius 0
  }

  &__arrows {
    position relative
    width 100%
    height 0
    display flex
    justify-content space-between
    z-index 1
    padding 0 12px
  }

  &__arrow {
    width 24px
    height 24px
    margin-bottom 36px
    relative bottom 64px
    opacity 0.4
    background none
    padding 0
    border none
    cursor pointer
    transition .2s

    &:hover {
      opacity 1
    }

    svg {
      width 100%
      height 100%
    }
  }

  &__dots {
    display flex
    align-self flex-end
    relative bottom 20px

    button {
      background none
      border none
      opacity 0.4
      padding 6px
      transition .2s
      cursor pointer

      &:hover {
        opacity .8
      }

      &:after {
        content ""
        width 32px
        height 2px
        background: var(--color_white);
        border-radius: 2px;
        display block
      }

      &.active {
        opacity 1
      }
    }
  }
}

.b-slide {
  position relative
  display flex
  overflow hidden

  &__title {
    font-weight: normal;
    font-size: 1.875em;
    line-height: 36px;
    color: var(--color_white);
    margin 0
    +below(1200px) {
      font-weight: 500;
      font-size: 1.375em;
      line-height: 28px;
    }
    +below(560px) {
      font-weight: 500;
      font-size: 1em;
      line-height: 20px;
    }
  }

  &__subtitle {
    display flex
    justify-content space-between
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--color_white);
    width 100%
  }

  &__img {
    width 100%
    height 490px
    object-fit cover
    object-position center
    transition .2s
    +below(1200px) {
      height 334px
    }
    +below(560px) {
      height 260px
    }

    ^[0]:hover & {
      transform scale(1.08)
    }
  }

  &__content {
    absolute left top
    width 100%
    height 100%
    z-index 1
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-end
    gap 8px
    padding 0 48px 44px
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 51.32%, rgba(0, 0, 0, 0.6) 83.4%);
    +below(768px) {
      padding 0 40px 36px
    }
  }
}
</style>
