<template>
  <div class="r-items">
    <ul class="r-items__list" v-if="items.data.length">
      <li class="r-items__item" v-for="(item, i) in items.data" :key="i">
        <RouteItemComponent :item="item" />
      </li>
    </ul>
    <span class="empty-message" v-else>Список пуст</span>
    <PaginationComponent :total="total" v-if="total > 1" @change="paginate" :page="page" />
  </div>
</template>
<script>
import PaginationComponent from "components/Pagination.vue";
import RouteItemComponent from "./RouteItemComponent.vue";

export default {
  name: "RouteItemsList",
  data() {
    return {
      page: 1,
      loading: false,
    };
  },
  computed: {
    total() {
      return Math.ceil(this.items.total / this.items.per_page);
    },
    items() {
      let items = [];
      if (this.$store.state.tourism.tourism) {
        items = this.$store.state.tourism.tourism;
      }
      return items;
    },
  },
  methods: {
    stopLoading() {
      this.loading = false;
    },
    paginate(page) {
      if (!this.loading) {
        this.page = page;
        this.loading = true;
        this.$emit("change", page);
      }
    },
  },
  components: { RouteItemComponent, PaginationComponent },
};
</script>

<style lang="stylus">
.r-items {
  display grid
  grid-gap 32px
  width 100%

  &__list {
    display grid
    grid-gap 32px
  }

  &__item {
    display flex
    width 100%
  }
}
</style>
