<template>
  <section class="t-excursion">
    <div class="t-excursion__slider">
      <RouteSlider :slides="slides" />
    </div>
    <div class="t-excursion__content">
      <TourismSearchComponent ref="search" @change="handleSearchChange" />
      <ExcursionItemsList ref="pagination" @change="handlePageChange" />
    </div>
  </section>
</template>

<script>
import TourismSearchComponent from "../../components/TourismSearchComponent.vue";
import ExcursionItemsList from "./components/ExcursionItemsList.vue";
import RouteSlider from "@/views/tourism/pages/route/components/RouteSliderComponent.vue";

export default {
  name: "TourismExcursionPage",
  data() {
    return {
      loading: false,
      searchQuery: null,
      page: 1,
      first: 6,
    };
  },
  computed: {
    slides() {
      let items = [];
      if (this.$store.state.tourism.tourism_important) {
        items = this.$store.state.tourism.tourism_important.map((item) => {
          item.route = {
            name: "tourism_open",
            params: {
              link: "routes",
              id: item.id,
              url: item.link,
            },
          };
          return item;
        });
      }
      return items;
    },
  },
  methods: {
    handleSearchChange(form) {
      this.searchQuery = form.title.value;
      this.loading = true;
      this.reload();
    },
    handlePageChange(page) {
      this.page = page;
      this.loading = true;
      this.reload();
    },
    stopLoading() {
      this.loading = false;
      this.$refs.search.stopLoading();
      this.$refs.pagination.stopLoading();
    },
    reload() {
      this.$emit("reload", this.$store, this.$route, {
        title: this.searchQuery,
        page: this.page,
        first: this.first,
      });
    },
  },
  components: { RouteSlider, ExcursionItemsList, TourismSearchComponent },
};
</script>

<style lang="stylus">
.t-excursion {
  display grid
  width 100%

  &__slider {
    display grid
    grid-template-columns 1fr
  }

  &__content {
    padding 32px 32px 48px
    display grid
    width 100%
    +below(1200px) {
      padding 32px 24px 48px
    }
    +below(768px) {
      padding 24px 24px 32px
    }
    +below(560px) {
      padding 16px
    }

    .t-search {
      margin-bottom 32px
      +below(768px) {
        margin-bottom 24px
      }
    }
  }
}
</style>
