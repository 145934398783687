<template>
  <article class="e-item">
    <div class="e-item__image-container">
      <img
        :src="item.head_img | image($store.state.api)"
        :alt="item.head_img | image_alt"
        class="e-item__image"
      />
    </div>
    <div class="e-item__body">
      <h2 class="e-item__title">{{ item.title }}</h2>
      <p class="e-item__subtitle">{{ item.description }}</p>
    </div>
  </article>
</template>

<script>
export default {
  name: "ExcursionItemComponent",
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.e-item {
  display grid
  grid-template-columns 4fr 8fr
  grid-gap 32px
  +below(1200px) {
    grid-template-columns 5fr 7fr
  }
  +below(768px) {
    grid-template-columns 1fr
  }

  &__image {
    width 100%
    height 100%
    object-fit cover
    object-position center
    transition .2s

    &-container {
      overflow hidden
      border-radius 4px
      width 100%
      +below(768px) {
        height 196px
      }

      &:hover img {
        transform scale(1.08)
      }
    }
  }

  &__body {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    grid-gap 16px
  }

  &__title {
    font-weight: 500;
    font-size: 1.375em;
    line-height: 28px;
    color: var(--color_black);
    margin 0
  }

  &__subtitle {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_black);
  }
}
</style>
