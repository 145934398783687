<template>
  <div class="tab-list">
    <ul class="tab-list__list">
      <li class="tab-list__item" v-for="(tab, i) in tabs" :key="i">
        <router-link
          class="tab-list__link ignore-icon"
          v-if="tab.route"
          :to="tab.route"
          :class="{ 'tab-list__link--active': i === activeTab }"
        >
          <Component :is="tab.icon" />
          <span>{{ tab.title }}</span>
        </router-link>
        <button
          type="button"
          class="tab-list__link"
          v-else
          :class="{ 'tab-list__link--active': i === activeTab }"
          @click="tab.event"
        >
          <Component :is="tab.icon" />
          <span>{{ tab.title }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabListComponent",
  props: {
    activeTab: {
      type: Number,
    },
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="stylus">
.tab-list {
  display flex
  align-items center
  justify-content stretch
  overflow scroll
  padding 16px
  width 100%
  border-bottom 1px solid var(--color_gray_divider);

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &__list {
    display flex
    align-items center
    justify-content flex-start
    flex-shrink 0
    gap 16px
  }

  &__link {
    font-weight: 400;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--color_dark);
    display grid
    grid-template-columns 40px auto
    align-items center
    grid-gap 8px
    padding 16px
    border-radius: 16px;
    background transparent
    border none
    cursor pointer
    transition .2s

    .icon {
      width 40px
      height 40px

      svg {
        width 100%
        height: 100%;
      }
    }

    &--active {
      background: var(--color_gray_light_o8);
      color: var(--main_color);
    }

    &:hover {
      color: var(--main_color);
    }

    &:active {
      transform scale(0.95)
    }
  }
}
</style>
