<template>
  <form class="t-search" @submit.prevent="emitSubmit" @reset.prevent="emitReset">
    <div
      class="t-search__container"
      v-if="isFilterVisible"
      :class="{ 't-search__container--2': categories.length }"
    >
      <div class="t-search__input">
        <span class="t-search__title">Поиск по названию</span>
        <input type="text" class="t-search__input" v-model="form.title.value" />
      </div>
      <div class="t-search__input" v-if="categories.length">
        <span class="t-search__title">Категория</span>
        <Multiselect
          track-by="id"
          label="title"
          v-model="form.category_id.value"
          :options="categories"
          placeholder="Все типы"
          selectLabel="Выбрать ↵"
          deselectLabel="Удалить ↵"
          selectedLabel="Выбрано"
          :searchable="true"
          :allow-empty="true"
        >
          <span slot="noOptions">Список пуст</span>
        </Multiselect>
      </div>
      <div class="t-search__buttons">
        <button type="reset" class="t-search__reset">Сбросить фильтр</button>
        <button type="submit" class="btn-blue_dark" :disabled="loading">
          <LoadingIndicator v-if="loading" title="Загрузка" />
          <template v-else>Найти</template>
        </button>
      </div>
    </div>
    <div class="t-search__mobile">
      <button type="reset" v-if="isFilterVisible" class="t-search__reset">Сбросить фильтр</button>
      <a
        @click.prevent="isFilterVisible = !isFilterVisible"
        class="t-search__mobile-toggle"
        :class="{ 't-search__mobile-toggle--open': isFilterVisible }"
        href="#"
      >
        <span v-if="isFilterVisible">Скрыть фильтр</span>
        <span v-else>Поиск и фильтр</span>
        <ArrowDown />
      </a>
    </div>
  </form>
</template>

<script>
import Multiselect from "vue-multiselect";
import LoadingIndicator from "components/LoadingIndicator.vue";
import ArrowDown from "components/svg/ArrowDown.vue";

export default {
  name: "TourismSearchComponent",
  props: {
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      isFilterVisible: true,
      form: {
        title: {
          value: null,
          message: null,
        },
        category_id: {
          value: null,
          message: null,
        },
      },
    };
  },
  methods: {
    stopLoading() {
      this.loading = false;
    },
    emitSubmit() {
      if (!this.loading) {
        this.loading = true;
        this.$emit("change", this.form);
      }
    },
    emitReset() {
      if (!this.loading) {
        this.loading = true;
        Object.keys(this.form).forEach((key) => {
          this.form[key].value = null;
        });
        this.$emit("change", this.form);
      }
    },
  },
  components: {
    ArrowDown,
    LoadingIndicator,
    Multiselect,
  },
};
</script>

<style lang="stylus">
@import '~vue-multiselect/dist/vue-multiselect.min.css'
@import "~@/styles/mixins/svg.styl"
.t-search {
  width 100%
  display grid
  +below(768px) {
    gap 24px
  }

  &__container {
    width 100%
    display grid
    grid-gap 32px
    grid-template-columns 9fr 3fr
    +below(768px) {
      gap 24px
    }
    +below(560px) {
      grid-template-columns 1fr
    }

    &--2 {
      grid-template-columns 6fr 3fr 3fr
      +below(1200px) {
        grid-template-columns 1fr 1fr
        & ^[0]__buttons {
          grid-column 1 / -1
        }
      }
      +below(560px) {
        grid-template-columns 1fr
        & ^[0]__buttons {
          grid-column 1 / -1
        }
      }
    }
  }

  &__input {
    display grid
    width 100%
    grid-gap 8px
  }

  &__title {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
  }

  &__buttons {
    display flex
    justify-content flex-end
    align-items flex-end
    gap 32px

    .btn-blue_dark {
      min-height auto
      height 44px
      width 130px
      font-weight: normal;
      font-size: 1em;
      line-height: 24px;
    }
    +below(768px) {
      gap 0
      & ^[0]__reset {
        display none
      }
      .btn-blue_dark {
        width 100%
      }
    }
  }

  &__reset {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    height 44px
    display inline-flex
    align-items center
    justify-content right
    color: var(--main_color);
    text-align right
    background none
    padding 0
    border 0
    cursor pointer

    &:hover {
      color var(--color_blue)
    }
    +below(768px) {
      height auto
    }
  }

  &__mobile {
    +above(769px) {
      display none
    }
    display flex
    justify-content flex-end
    align-items center
    gap 32px
    +below(560px) {
      gap 16px
      align-items flex-end
      flex-direction column
    }

    & ^[0]__reset {
      font-weight: normal;
      font-size: 1em;
      line-height: 24px;
      color: var(--color_blue);
    }

    &-toggle {
      font-weight: normal;
      font-size: 1em;
      line-height: 24px;
      color: var(--main_border_color);
      display inline-flex
      align-items center
      gap 8px

      &--open .icon {
        transform rotate(180deg)
      }

      .icon {
        width 24px
        height 24px
        transition .2s

        svg {
          width 100%
          height 100%
          svg(var(--main_border_color))
        }
      }
    }
  }
}
</style>
