<template>
  <div class="e-items">
    <ul class="e-items__list" v-if="items.data.length">
      <li class="e-items__item" v-for="(item, i) in items.data" :key="i">
        <ExcursionItemComponent :item="item" />
      </li>
    </ul>
    <span class="empty-message" v-else>Список пуст</span>
    <PaginationComponent :total="total" v-if="total > 1" @change="paginate" :page="page" />
  </div>
</template>

<script>
import PaginationComponent from "components/Pagination.vue";
import ExcursionItemComponent from "./ExcursionItemComponent.vue";

export default {
  name: "ExcursionItemsList",
  data() {
    return {
      page: 1,
      loading: false,
    };
  },
  computed: {
    total() {
      return Math.ceil(this.items.total / this.items.per_page);
    },
    items() {
      let items = [];
      if (this.$store.state.tourism.tourism) {
        items = this.$store.state.tourism.tourism;
      }
      return items;
    },
  },
  methods: {
    stopLoading() {
      this.loading = false;
    },
    paginate(page) {
      if (!this.loading) {
        this.page = page;
        this.loading = true;
        this.$emit("change", page);
      }
    },
  },
  components: { ExcursionItemComponent, PaginationComponent },
};
</script>

<style lang="stylus">
.e-items {
  display grid
  grid-gap 32px
  width 100%
  +below(1200px) {
    grid-gap 24px
  }

  &__list {
    display grid
    grid-gap 32px
    +below(1200px) {
      grid-template-columns repeat(3, 1fr)
      grid-gap 24px
    }
    +below(860px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(560px) {
      grid-template-columns 1fr
    }
  }

  &__item {
    display flex
    width 100%
  }
}
</style>
