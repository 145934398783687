<template>
  <section class="t-route">
    <div class="t-route__slider">
      <RouteSlider :slides="slides" />
      <SendIdeaComponent />
    </div>
    <div class="t-route__content">
      <RouteTabsComponent ref="tabs" :tabs="tabs" :activeTab="activeTabIndex" @change="handleTabChange" />
      <TourismSearchComponent ref="search" @change="handleSearchChange" />
      <RouteItemsList ref="pagination" @change="handlePageChange" />
    </div>
  </section>
</template>

<script>
import SendIdeaComponent from "./components/SendIdeaComponent.vue";
import RouteTabsComponent from "./components/RouteTabsComponent.vue";
import TourismSearchComponent from "../../components/TourismSearchComponent.vue";
import RouteItemsList from "./components/RouteItemsList.vue";
import RouteSlider from "@/views/tourism/pages/route/components/RouteSliderComponent.vue";

export default {
  name: "TourismRoutePage",
  data() {
    return {
      loading: false,
      activeTab: null,
      searchQuery: null,
      page: 1,
      first: 6,
      activeTabIndex: null,
    };
  },
  computed: {
    slides() {
      let items = [];
      if (this.$store.state.tourism.tourism_important) {
        items = this.$store.state.tourism.tourism_important.map((item) => {
          item.route = {
            name: "tourism_open",
            params: {
              link: "routes",
              id: item.id,
              url: item.link,
            },
          };
          return item;
        });
      }
      return items;
    },
    tabs() {
      let tabs = [
        {
          id: -1,
          title: "Все маршруты",
        },
      ];
      if (this.$store.state.tourism.category_routes) {
        tabs = tabs.concat(this.$store.state.tourism.category_routes);
      }
      return tabs;
    },
  },
  beforeMount() {
    this.activeTab = this.tabs[0];
    this.activeTabIndex = 0;
  },
  methods: {
    handleTabChange(item, i) {
      this.activeTab = item;
      this.activeTabIndex = i;
      this.loading = true;
      this.reload();
    },
    handleSearchChange(form) {
      this.searchQuery = form.title.value;
      this.loading = true;
      this.reload();
    },
    handlePageChange(page) {
      this.page = page;
      this.loading = true;
      this.reload();
    },
    stopLoading() {
      this.loading = false;
      this.$refs.tabs.stopLoading();
      this.$refs.search.stopLoading();
      this.$refs.pagination.stopLoading();
    },
    reload() {
      this.$emit("reload", this.$store, this.$route, {
        category_id: this.activeTab.id === -1 ? undefined : this.activeTab.id,
        title: this.searchQuery,
        page: this.page,
        first: this.first,
      });
    },
  },
  components: { RouteSlider, RouteItemsList, TourismSearchComponent, RouteTabsComponent, SendIdeaComponent },
};
</script>

<style lang="stylus">
.t-route {
  display grid
  width 100%

  &__slider {
    display grid
    grid-template-columns 9fr 3fr
    +below(1200px) {
      grid-template-columns 1fr
    }
  }

  &__content {
    padding 32px 32px 48px
    display grid
    width 100%
    +below(1200px) {
      padding 32px 24px 48px
    }
    +below(768px) {
      padding 24px 24px 32px
    }
    +below(560px) {
      padding 16px
    }

    .r-tabs {
      margin-bottom 24px
      +below(560px) {
        margin-bottom 16px
      }
    }

    .t-search {
      margin-bottom 32px
      +below(768px) {
        margin-bottom 24px
      }
    }
  }
}
</style>
