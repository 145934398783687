<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <TabListComponent :tabs="types" :active-tab="activeTypeIndex" />
        <Component ref="page" :is="activeType.page" v-if="activeType" @reload="reloadData" />
      </div>
    </div>
  </main>
</template>

<script>
import TabListComponent from "./components/TabListComponent.vue";
import RouteIcon from "./components/svg/RouteIcon.vue";
import VirtualExcursionIcon from "./components/svg/VirtualExcursionIcon.vue";
import AccommodationIcon from "./components/svg/AccommodationIcon.vue";
import GastronomyIcon from "./components/svg/GastronomyIcon.vue";
import PhotoGalleryIcon from "./components/svg/PhotoGalleryIcon.vue";
import EventsIcon from "./components/svg/EventsIcon.vue";
import MemoIcon from "./components/svg/MemoIcon.vue";
import TourismRoutePage from "./pages/route/index.vue";
import TourismExcursionPage from "./pages/excursion/index.vue";
import TourismAccommodationPage from "./pages/accommodation/index.vue";
import TourismGastronomyPage from "./pages/gastronomy/index.vue";
import TourismPhotoGalleryPage from "./pages/photogallery/index.vue";
import TourismMemoPage from "./pages/memo/index.vue";
import axios from "axios";

// Главные вкладки
let types = [
  {
    id: "routes",
    code: 1,
    first: 6,
    title: "Маршруты",
    icon: RouteIcon,
    route: { name: "tourism", params: { link: "routes" } },
    page: TourismRoutePage,
  },
  {
    id: "excursions",
    code: 2,
    first: 6,
    title: "Виртуальные экскурсии",
    icon: VirtualExcursionIcon,
    route: { name: "tourism", params: { link: "excursions" } },
    page: TourismExcursionPage,
  },
  {
    id: "accommodation",
    code: 3,
    first: 16,
    title: "Размещение",
    icon: AccommodationIcon,
    route: { name: "tourism", params: { link: "accommodation" } },
    page: TourismAccommodationPage,
  },
  {
    id: "gastronomy",
    code: 4,
    first: 16,
    title: "Гастрономия",
    icon: GastronomyIcon,
    route: { name: "tourism", params: { link: "gastronomy" } },
    page: TourismGastronomyPage,
  },
  {
    id: "photogallery",
    code: 5,
    first: 9,
    title: "Фотогалерея",
    icon: PhotoGalleryIcon,
    route: { name: "tourism", params: { link: "photogallery" } },
    page: TourismPhotoGalleryPage,
  },
  {
    id: 7,
    code: 7,
    first: 6,
    title: "События",
    icon: EventsIcon,
    route: { name: "posters" },
    page: TourismRoutePage,
  },
  {
    id: "memo",
    code: 6,
    first: 6,
    title: "Памятка туристу",
    icon: MemoIcon,
    route: { name: "tourism", params: { link: "memo" } },
    page: TourismMemoPage,
  },
];

// Функция загрузки вынесена отдельно
async function load(store, route, params = {}) {
  let activeType = types.find((t) => t.id === route.params.link);
  params.first = activeType.first;
  await axios
    .get(store.state.api + "/api/tourism/" + activeType.code, {
      params: params,
    })
    .then(({ data }) => {
      store.state.mayor = data.mayor;
      store.state.agreement = data.agreement;
      store.state.tourism = data;
    })
    .catch(() => {});
}

export default {
  async asyncData({ store, route }) {
    // Вызов функции загрузки с параметрами по умолчанию
    await load(store, route);
  },
  name: "TourismOpen",
  data() {
    return {
      types: types,
    };
  },
  computed: {
    activeType() {
      return this.types[this.activeTypeIndex] || -1;
    },
    activeTypeIndex() {
      return this.types.findIndex((t) => t.id === this.$route.params.link);
    },
  },
  methods: {
    async reloadData() {
      await this.load(...arguments);
      this.$refs.page.stopLoading();
    },
    load,
  },
  components: { TabListComponent },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>
