<template>
  <figure class="icon">
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.5 11.25C29.9162 11.25 31.875 9.29125 31.875 6.875C31.875 4.45875 29.9162 2.5 27.5 2.5C25.0838 2.5 23.125 4.45875 23.125 6.875C23.125 9.29125 25.0838 11.25 27.5 11.25ZM27.5 10C29.2259 10 30.625 8.60089 30.625 6.875C30.625 5.14911 29.2259 3.75 27.5 3.75C25.7741 3.75 24.375 5.14911 24.375 6.875C24.375 8.60089 25.7741 10 27.5 10Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.5048 21.8508C36.875 21.7064 36.875 20.9327 36.875 20C36.875 19.2671 36.875 18.6324 36.2102 18.324L37.5 11.875H27.5L26.25 18.125H23.75V15.625C23.75 15.2798 23.4702 15 23.125 15C22.7798 15 22.5 15.2798 22.5 15.625V18.75C22.5 19.0952 22.7798 19.375 23.125 19.375H27.5C27.8452 19.375 28.125 19.6548 28.125 20C28.125 20.3452 27.8452 20.625 27.5 20.625H21.25C20.9048 20.625 20.625 20.3452 20.625 20V15C20.625 13.9645 21.4645 13.125 22.5 13.125H25.625C25.9702 13.125 26.25 12.8452 26.25 12.5C26.25 12.1548 25.9702 11.875 25.625 11.875H22.5C20.7741 11.875 19.375 13.2741 19.375 15V20C19.375 21.0355 20.2145 21.875 21.25 21.875H25.5078L25 24.375H35L35.5048 21.8508ZM35.6062 20.568C35.623 20.4396 35.625 20.2788 35.625 20C35.625 19.7212 35.623 19.5604 35.6062 19.432L35.6062 19.4318C35.5056 19.4053 35.318 19.375 35 19.375C34.6548 19.375 34.375 19.6548 34.375 20C34.375 20.3452 34.6548 20.625 35 20.625C35.318 20.625 35.5056 20.5947 35.6062 20.5682L35.6062 20.568ZM29.375 20C29.375 18.9645 28.5355 18.125 27.5 18.125H27.5248L28.5248 13.125H35.9752L34.9752 18.1252C33.9511 18.1384 33.125 18.9727 33.125 20C33.125 20.7712 33.5906 21.4336 34.2559 21.7216L33.9752 23.125H26.5248L26.7969 21.875H27.5C28.5355 21.875 29.375 21.0355 29.375 20Z"
        fill="#333333"
      />
      <path
        d="M22.5 22.5C22.1548 22.5 21.875 22.7798 21.875 23.125V35C21.875 36.3807 22.9943 37.5 24.375 37.5C25.7557 37.5 26.875 36.3807 26.875 35V26.875C26.875 26.5298 27.1548 26.25 27.5 26.25C27.8452 26.25 28.125 26.5298 28.125 26.875V35C28.125 36.3807 29.2443 37.5 30.625 37.5C32.0057 37.5 33.125 36.3807 33.125 35V26.25C33.125 25.9048 32.8452 25.625 32.5 25.625C32.1548 25.625 31.875 25.9048 31.875 26.25V35C31.875 35.6904 31.3154 36.25 30.625 36.25C29.9346 36.25 29.375 35.6904 29.375 35V26.875C29.375 25.8395 28.5355 25 27.5 25C26.4645 25 25.625 25.8395 25.625 26.875V35C25.625 35.6904 25.0654 36.25 24.375 36.25C23.6846 36.25 23.125 35.6904 23.125 35V23.125C23.125 22.7798 22.8452 22.5 22.5 22.5Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3339 11.9807L17.628 9.02027C16.46 6.99324 13.54 6.99324 12.372 9.02027L2.91108 25.4392C1.74307 27.4662 3.20308 30 5.53911 30H20.625V23.125C20.625 23.1042 20.6253 23.0834 20.626 23.0627C19.1991 22.7735 18.125 21.5122 18.125 20V15C18.125 13.8292 18.5849 12.7658 19.3339 11.9807ZM15 11.875C13.6453 11.875 12.5715 13.018 12.656 14.3701L13.0081 20.0037C13.0738 21.0555 13.9461 21.875 15 21.875C16.0539 21.875 16.9262 21.0555 16.992 20.0037L17.3441 14.3701C17.4286 13.018 16.3547 11.875 15 11.875ZM15 27.5C16.0355 27.5 16.875 26.6605 16.875 25.625C16.875 24.5895 16.0355 23.75 15 23.75C13.9645 23.75 13.125 24.5895 13.125 25.625C13.125 26.6605 13.9645 27.5 15 27.5Z"
        fill="#FABA4A"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "MemoIcon",
};
</script>
