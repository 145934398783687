<template>
  <section class="t-memo">
    <h1 class="t-memo__title">Памятка туриста</h1>
    <EditorJSComponent :text="item.text" />
  </section>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "TourismMemoPage",
  computed: {
    item() {
      let item = {};
      if (this.$store.state.tourism.tourism && this.$store.state.tourism.tourism.data.length) {
        item = this.$store.state.tourism.tourism.data[0];
      }
      return item;
    },
  },
  components: { EditorJSComponent },
};
</script>

<style lang="stylus">
.t-memo {
  display flex
  flex-direction column
  padding 0 32px 48px

  &__title {
    padding 48px 0 32px
    font-weight: normal;
    font-size: 1.875em;
    line-height: 36px;
    color var(--color_black)
    margin 0
  }
}
</style>
