<template>
  <figure class="icon">
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="9.375" y="25" width="21.3281" height="12.5" fill="#FABA4A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 17.5C22.0711 17.5 23.75 15.8211 23.75 13.75C23.75 11.6789 22.0711 10 20 10C17.9289 10 16.25 11.6789 16.25 13.75C16.25 15.8211 17.9289 17.5 20 17.5ZM20 16.25C21.3807 16.25 22.5 15.1307 22.5 13.75C22.5 12.3693 21.3807 11.25 20 11.25C18.6193 11.25 17.5 12.3693 17.5 13.75C17.5 15.1307 18.6193 16.25 20 16.25Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 20C23.4518 20 26.25 17.2018 26.25 13.75C26.25 10.2982 23.4518 7.5 20 7.5C16.5482 7.5 13.75 10.2982 13.75 13.75C13.75 17.2018 16.5482 20 20 20ZM20 18.75C22.7614 18.75 25 16.5114 25 13.75C25 10.9886 22.7614 8.75 20 8.75C17.2386 8.75 15 10.9886 15 13.75C15 16.5114 17.2386 18.75 20 18.75Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.875 11.875V7.5H31.25V11.875H26.875ZM28.125 8.75H30V10.625H28.125V8.75Z"
        fill="#333333"
      />
      <path d="M8.75 18.125V19.375H12.5V18.125H8.75Z" fill="#333333" />
      <path d="M15 18.125H13.75V19.375H15V18.125Z" fill="#333333" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.875 5H15C15 3.96447 14.1605 3.125 13.125 3.125H10.625C9.58947 3.125 8.75 3.96447 8.75 5L8.125 5C7.08947 5 6.25 5.83947 6.25 6.875V25.625C6.25 26.6605 7.08947 27.5 8.125 27.5H11.25V33.125H28.75V27.5H31.875C32.9105 27.5 33.75 26.6605 33.75 25.625V6.875C33.75 5.83947 32.9105 5 31.875 5ZM10.625 4.375H13.125C13.4702 4.375 13.75 4.65482 13.75 5L10 5C10 4.65482 10.2798 4.375 10.625 4.375ZM28.75 26.25H31.875C32.2202 26.25 32.5 25.9702 32.5 25.625V21.875H7.5V25.625C7.5 25.9702 7.77982 26.25 8.125 26.25H11.25V25H8.75V23.75H31.25V25H28.75V26.25ZM7.5 20.625H32.5V6.875C32.5 6.52982 32.2202 6.25 31.875 6.25H8.125C7.77982 6.25 7.5 6.52982 7.5 6.875V20.625ZM27.5 25H23.125H12.5V28.75L15.625 26.25L18.2863 28.0242L23.125 25L27.5 27.5V25ZM27.5 28.75L23.125 26.25L19.254 28.6694L20.5469 29.5312L19.5508 30.1172L15.625 27.5L12.5 30V31.875H27.5V28.75Z"
        fill="#333333"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "PhotoGalleryIcon",
};
</script>
