<template>
  <div class="r-tabs">
    <ul class="r-tabs__list">
      <li class="r-tabs__item" v-for="(item, i) in tabs" :key="i">
        <button
          type="button"
          class="r-tabs__button"
          :class="{ 'r-tabs__button--active': activeTab === i }"
          @click="emitChange(item, i)"
        >
          {{ item.title }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "RouteTabsComponent",
  props: {
    activeTab: {
      type: Number,
    },
    tabs: {
      type: Array,
    },
  },
  methods: {
    stopLoading() {
      this.loading = false;
    },
    emitChange(item, i) {
      if (!this.loading) {
        this.loading = true;
        this.$emit("change", item, i);
      }
    },
  },
};
</script>

<style lang="stylus">
.r-tabs {
  display flex
  align-items center
  width 100%
  overflow scroll

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &__list {
    display flex
    align-items center
    justify-content flex-start
    gap 32px
    flex-shrink 0
    width 100%
    +below(1200px) {
      gap 24px
    }
  }

  &__item {
    display inline-flex
    align-items center
    justify-content center
    flex-shrink 0
    white-space nowrap
  }

  &__button {
    background none
    border none
    padding-bottom 8px
    border-bottom 3px solid transparent
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--color_gray_dark);
    cursor pointer
    transition .2s
    +below(1200px) {
      font-size 1em
    }

    &--active {
      color var(--color_black)
      border-color var(--main_color)
    }
  }
}
</style>
