<template>
  <div class="mod">
    <div class="mod__header">
      <span class="mod__title">Направить идею</span>
      <div class="mod__header-buttons">
        <button
          v-if="![formLoading, fileLoading].includes(true)"
          @click="close"
          type="button"
          class="mod__close"
        >
          <CloseIcon />
        </button>
      </div>
    </div>
    <div class="mod__body">
      <form class="mod__form">
        <span class="mod__text">
          Если у вас есть идеи и предложения по развитию туризма в регионе – направляйте их в наш адрес
        </span>
        <div style="display: grid; grid-gap: 8px">
          <span class="mod__text">Краткое описание предложения</span>
          <textarea placeholder="Текст предложения" rows="10"></textarea>
        </div>
        <div style="display: grid; grid-gap: 8px">
          <span class="mod__text mod__text--small">
            Вы можете приложить изображение, более полно раскрывающее суть вашего обащения. Допустимые форматы
            файла: jpg, png. Размер файла не может превышать 5 МБ.
          </span>
          <button type="button" class="btn-transpar_blue_dark" style="margin-right: auto">
            Добавить файл
          </button>
        </div>
        <div class="mod__buttons">
          <button
            class="mod__buttons-accept"
            :class="{
              'mod__buttons-accept--loading': [formLoading, fileLoading].includes(true),
            }"
            style="margin-right: auto; width: 170px"
            type="button"
            @click="submitForm"
          >
            <template v-if="[formLoading, fileLoading].includes(true)">Отправка</template>
            <template v-else>Отправить</template>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";

export default {
  name: "NewClaimModal",
  props: {
    options: Object,
  },
  data() {
    return {
      form: {
        text: {
          value: null,
          messages: [],
          required: true,
          type: "string",
        },
        image: {
          value: null,
          messages: [],
          required: true,
          refName: "image",
          accept: "image/*",
          type: "file",
        },
      },
      formLoading: false,
      fileLoading: false,
      fileName: null,
    };
  },
  methods: {
    submitForm() {},
    close() {
      this.$emit("close");
    },
  },
  components: {
    CloseIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/mod.styl"
@import "~@/styles/parts/mod-input.styl"
</style>
