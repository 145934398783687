<template>
  <section class="t-gallery">
    <p class="t-gallery__description" v-show="false">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus enim et ex impedit iusto magnam sed!
      Ad adipisci aliquam asperiores beatae culpa debitis dolore dolores eius esse et eveniet exercitationem
      explicabo harum iure labore laudantium minus molestiae nihil officia porro quos ratione recusandae rem
      saepe, sint soluta tenetur ut veritatis!
    </p>
    <div class="t-gallery__items" id="lightgallery">
      <a
        :href="image.head_img | image($store.state.api)"
        class="t-gallery__item"
        v-for="(image, i) in images.data"
        :key="i"
      >
        <img
          :src="image.head_img | image($store.state.api)"
          :alt="image.head_img | image_alt"
          class="t-gallery__image"
        />
      </a>
    </div>
    <a
      @click.prevent
      href="#"
      class="show-more-link"
      v-if="images.total > images.per_page"
      @change="changePerPage"
    >
      Показать ещё
    </a>
  </section>
</template>

<script>
export default {
  name: "TourismPhotoGalleryPage",
  data() {
    return {
      loading: false,
      page: 1,
      first: 9,
    };
  },
  computed: {
    images() {
      let items = [];
      if (this.$store.state.tourism.tourism) {
        items = this.$store.state.tourism.tourism;
      }
      return items;
    },
  },
  methods: {
    changePerPage() {
      if (!this.loading) {
        this.first = this.first + 9;
        this.loading = true;
        this.reload();
      }
    },
    stopLoading() {
      this.loading = false;
    },
    reload() {
      this.$emit("reload", this.$store, this.$route, {
        page: this.page,
        first: this.first,
      });
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    require(["lightgallery.js"], () => {
      // eslint-disable-next-line no-undef
      require(["lg-thumbnail.js"], () => {
        // eslint-disable-next-line no-undef
        lightGallery(document.getElementById("lightgallery"), {
          thumbnail: true,
        });
      });
    });
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/lightgallery.styl"
.t-gallery {
  display flex
  flex-direction column
  padding 24px 32px 48px
  +below(1200px) {
    padding 24px 24px 48px
  }
  +below(768px) {
    padding 24px 24px 32px
  }
  +below(560px) {
    padding 16px
  }

  &__description {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
    margin 0 0 24px
    +below(560px) {
      display none
    }
  }

  &__items {
    column-count: 3;
    column-gap: 10px;
    +below(768px) {
      column-count: 2;
    }
    +below(560px) {
      column-count: 1;
    }
  }

  &__item {
    margin-bottom 16px
    display grid
    break-inside: avoid;
  }
}
</style>
