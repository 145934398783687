<template>
  <div class="a-items">
    <ul class="a-items__list" v-if="items.data.length">
      <li class="a-items__item" v-for="(item, i) in items.data" :key="i">
        <AccommodationItemComponent :item="item" :link="link" />
      </li>
    </ul>
    <span class="empty-message" v-else>Список пуст</span>
    <PaginationComponent :total="total" v-if="total > 1" @change="paginate" :page="page" />
  </div>
</template>

<script>
import PaginationComponent from "components/Pagination.vue";
import AccommodationItemComponent from "./AccommodationItemComponent.vue";

export default {
  name: "AccommodationItemsList",
  props: {
    link: {
      type: String,
      default() {
        return "accommodation";
      },
    },
  },
  data() {
    return {
      page: 1,
      loading: false,
    };
  },
  computed: {
    total() {
      return Math.ceil(this.items.total / this.items.per_page);
    },
    items() {
      let items = [];
      if (this.$store.state.tourism.tourism) {
        items = this.$store.state.tourism.tourism;
      }
      return items;
    },
  },
  methods: {
    stopLoading() {
      this.loading = false;
    },
    paginate(page) {
      if (!this.loading) {
        this.page = page;
        this.loading = true;
        this.$emit("change", page);
      }
    },
  },
  components: { AccommodationItemComponent, PaginationComponent },
};
</script>

<style lang="stylus">
.a-items {
  display grid
  grid-gap 32px
  width 100%
  +below(1200px) {
    grid-gap 24px
  }

  &__list {
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 32px
    +below(1200px) {
      grid-template-columns repeat(3, 1fr)
      grid-gap 24px
    }
    +below(860px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(560px) {
      grid-template-columns 1fr
    }
  }

  &__item {
    display flex
    width 100%
  }
}
</style>
